h1, h2 {
    border: 1px solid #ddd;
    text-align: center;
    padding: 6px;
}

.calculator {
    background-color: #666;
    margin-top: 24px;
    color: #ddd;
    padding: 24px;
    box-sizing: border-box;

    .customSelector {
        color: #ddd;
        padding: 0;

        .MuiSelect-nativeInput, .MuiOutlinedInput-notchedOutline {
            border: 0;
            border-image: none;
        }
    }
}
